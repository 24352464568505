* {
  direction: rtl;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Segoe UI', Arial, sans-serif;
}

body {
  background-color: #000;
  color: #fff;
  overflow-x: hidden;
}

.header {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../public/image/picForAd.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.logo {
  width: 350px;
}

nav {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

nav a {
  color: #D4B062;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
  padding: 5px 10px;
}

nav a:hover {
  color: #fff;
}

h1 {
  font-size: clamp(2em, 5vw, 3.5em);
  color: #D4B062;
  margin-bottom: 20px;
  font-weight: 300;
}

.subtitle {
  font-size: clamp(1.2em, 3vw, 1.8em);
  color: #D4B062;
  margin-bottom: 40px;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
}

.feature-box {
  border: 1px solid #D4B062;
  padding: 20px;
  text-align: center;
  background-color: black;
  color: #D4B062;
}

.about-section {
  padding: 80px 20px;
  background-color: #111;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
}

.about-text h2 {
  color: #D4B062;
  font-size: clamp(1.8em, 4vw, 2.5em);
  margin-bottom: 30px;
  font-weight: 300;
}

.about-text p {
  font-size: clamp(1em, 2vw, 1.2em);
  line-height: 1.8;
  margin-bottom: 20px;
  color: #fff;
}

.gallery {
  max-width: 1400px;
  margin: 4rem auto;
  padding: 0 2rem;
  columns: 3;
  column-gap: 2rem;
}

.gallery-item {
  break-inside: avoid;
  margin-bottom: 2rem;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(147, 51, 234, 0.2);
  transition: transform 0.3s;
}

.gallery-item:hover {
  transform: translateY(-10px);
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.projects-section {
  padding: 80px 20px;
  background-color: #000;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.project-card {
  position: relative;
  height: 300px;
  overflow: hidden;
  border: 1px solid #D4B062;
}

.project-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.project-card:hover img {
  transform: scale(1.05);
}

.project-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #D4B062;
  padding: 15px;
  transform: translateY(100%);
  transition: transform 0.3s;
}

.project-card:hover .project-overlay {
  transform: translateY(0);
}

.contact-section {
  padding: 80px 20px;
  background-color: #111;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.contact-form h2 {
  color: #D4B062;
  font-size: clamp(1.8em, 4vw, 2.5em);
  margin-bottom: 30px;
  text-align: center;
  font-weight: 300;
}

.form-group {
  margin-bottom: 20px;
}

input,
textarea {
  width: 100%;
  padding: 15px;
  background-color: transparent;
  border: 1px solid #D4B062;
  color: #fff;
  font-size: 16px;
}

button {
  background-color: #D4B062;
  color: #000;
  padding: 15px 40px;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

button:hover {
  background-color: #fff;
}

@media (max-width: 768px) {
  nav {
      padding: 10px;
      gap: 15px;
      justify-content: space-around;
  }

  nav a {
      font-size: 16px;
      padding: 8px;
  }

  .header {
      padding-top: 80px;
  }

  .features {
      max-width: 100%;
  }

  .feature-box {
      padding: 15px;
      font-size: 14px;
  }

  .project-card {
      height: 250px;
  }

  .gallery {
      columns: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
      columns: 1;
  }
}



























/* Footer */
.Footer {
  height: 100pt;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexFooter {
  display: flex;
  justify-content: space-evenly;
  height: 75px;
  width: 80%;
}



/* Questions Section */
.questions-container {
  width: 70%;
  margin: 0 auto;
}

.styles_row-content-text__2sgAB.row-content-text {
  text-align: right !important;
}

/* SVG Container */
.svg-container {
  width: 100%;
}

.svg-container svg {
  display: block;
  width: 100%;
  height: auto;
}
